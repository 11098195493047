// 选择城市弹框组件
import TravelStandardHotelCityMultipleSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/travel-standard-hotel-city-multiple-selector/2.0.1/index.vue";
import consumer_web_policyConfig_cityTemplateDetail from '@/lib/data-service/haolv-default/consumer_web_policyConfig_cityTemplateDetail'
import consumer_web_policyConfig_updateCityTemplate from '@/lib/data-service/haolv-default/consumer_web_policyConfig_updateCityTemplate'
import consumer_web_policyConfig_addCityTemplate from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addCityTemplate'
import consumer_web_policyConfig_defaultCitySort from '@/lib/data-service/haolv-default/consumer_web_policyConfig_defaultCitySort'
export default {
    data() {
        const validateSortName = (rule, value, callback) => {
            let sortNameNum = 0;
            let sortNameBox = [];
            if (!this.indexSortName) {
                let typeBox = false;
                this.form.sortList.forEach((item,index) => {
                    if (sortNameBox.indexOf(item.sortName) != -1) {
                        typeBox = true;
                    }
                    sortNameBox.push(item.sortName);
                });
                if (typeBox) {
                    return callback(new Error("城市类别名称重复"));
                }
            } else {
                this.form.sortList.forEach((item,index) => {
                    if (item.sortName == this.form.sortList[this.indexSortName].sortName) {
                        sortNameNum++;
                    }
                });
                if (sortNameNum >= 2) {
                    return callback(new Error("城市类别名称重复"));
                }
            }
            callback();
            // callback();
            // callback(new Error("请选择出差人员"));
        };
        return {
            indexSortName: null,
            rules: {
                sortName: [
                    {validator: validateSortName, trigger: "blur"},
                ],
            },
            loading: false,
            templateId: '',
            form: {
                templateId: '',
                templateName: '',
                sortList: [],
            },
            otherForm: {
                templateName: '其他城市',
            },
            chooseClassDialogVisible: false,
            selectedIdList: [],
            cityClassList: [
                {
                    name: '一级城市',
                    id: 1,
                },
                {
                    name: '二级城市',
                    id: 2,
                },
                {
                    name: '三级城市',
                    id: 3,
                },
                {
                    name: '自定义城市',
                    id: 4,
                }
            ],
            activeCityClass: null,
            defaultCity: {},
            activeEditIndex: '',
            editType: 'add', // add新建 edit编辑
            newSort: {},
        }
    },
    components: {TravelStandardHotelCityMultipleSelector},
    created() {
    },
    mounted() {
        this.getDefaultCity();
    },
    activated() {
        this.reactForm();
        this.templateId = this.$route.query.templateId || '';
        if (this.templateId) {
            this.getDetail();
        }
        this.$refs.aTravelStandardHotelCityMultipleSelector.init_parameter.btn_ok_click_event_handler = this.saveCity
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        focusInput(index) {
            this.indexSortName = index;
        },
        getDefaultCity() {
            consumer_web_policyConfig_defaultCitySort().then(res=>{
                this.defaultCity = res.datas;
            })
        },
        reactForm() {
            this.form = {
                templateId: '',
                templateName: '',
                sortList: [],
            }
        },
        getDetail() {
            this.loading = true;
            consumer_web_policyConfig_cityTemplateDetail({templateId: this.templateId}).then((res)=>{
                let data = res.datas;
                let delIndex = '';
                data.sortDetailList.forEach((value, index)=>{
                    if (value.sortName === '其他城市') {
                        delIndex = index
                    }
                    let cityIds = [];
                    value.sortCityIds.forEach(value1=>{
                        cityIds.push(value1.cityId)
                    });
                    value.cityIds = cityIds;

                })
                data.sortList = data.sortDetailList;
                data.sortList.splice(delIndex, 1);
                delete data['sortDetailList'];
                this.form = data

            }).finally(()=>{
                this.loading = false;
            })
        },
        delItem(index) {
            this.form.sortList.splice(index, 1);
        },
        editCity(val, index) {
            this.editType = 'edit';
            this.activeEditIndex = index;
            this.$refs.aTravelStandardHotelCityMultipleSelector.show();
            this.selectedIdList = JSON.parse(JSON.stringify(val.cityIds));
            console.log(this.selectedIdList)
        },
        addNewClass() {
            if (this.form.sortList.length >= 29) {
                this.$message.warning('城市类别不能超过30个')
                return
            }
            this.editType = 'add';
            this.activeCityClass = null;
            this.chooseClassDialogVisible = true;
        },
        createNewClass() {
            if (this.activeCityClass === null) {
                return
            }
            let cityList = [];
            let cityIds = [];
            let sortCityName = [];
            let template = '';
            switch (this.activeCityClass) {
                case 1:
                    cityList = this.defaultCity.firstCityIds;
                    template = '一级城市';
                    break
                case 2:
                    cityList = this.defaultCity.secondCityIds;
                    template = '二级城市';
                    break
                case 3:
                    cityList = this.defaultCity.thirdCityIds;
                    template = '三级城市';
                    break
                case 4:
                    template = '自定义城市';
                    break
            }
            if (cityList.length > 0) {
                const allCity = this.$refs.aTravelStandardHotelCityMultipleSelector.left_tree.all_entity_list
                console.log(allCity);
                allCity.forEach(value=>{
                    if (cityList.indexOf(value.id) > -1) {
                        sortCityName.push(value.cityName); // .slice(1)
                    }
                })
            }
            this.chooseClassDialogVisible = false;
            this.$refs.aTravelStandardHotelCityMultipleSelector.show();
            this.selectedIdList = JSON.parse(JSON.stringify(cityList));
            this.newSort = {
                sortName: template,
                sortId: '',
                cityIds: cityList,
                sortCityName: sortCityName.join('，'),
            }
            /*this.form.sortList.push({
                sortName: template,
                sortId: '',
                cityIds: cityList,
                sortCityName: sortCityName.join('，'),
            });*/

        },
        back() {
            this.$router.go(-1);
        },
        submit() {
            this.$refs.journeyForm.validate((valid) => {
                if (valid) {
                    if (this.form.templateName === '') {
                        this.$message({
                            type: "warning",
                            message: '请填写模板名称'
                        })
                        return
                    }
                    this.loading = true;
                    let submitTemplate = this.templateId ? consumer_web_policyConfig_updateCityTemplate : consumer_web_policyConfig_addCityTemplate;
                    submitTemplate(this.form)
                        .then(res => {
                            this.$message({
                                type: "success",
                                message: '保存成功'
                            });
                            // this.getDetail();
                            this.back();
                        })
                        .catch(rej => {
                            if (rej.data.code == "300001") {
                                this.$confirm(rej.data.msg, '温馨提示', {
                                    confirmButtonText: '前往修改',
                                    cancelButtonText: '我知道了',
                                }).then(() => {
                                    this.$router.push({name: 'admin-hotel-standard-list'});
                                }).catch(() => {

                                });
                            } else {
                                this.$message({
                                    message: rej.data.msg,
                                    type: "warning",
                                })
                            }
                            this.loading = false;
                        })
                        .finally(()=>{
                            this.loading = false;
                        })
                } else {

                }
            });
        },
        saveCity(selected_entity_list) {
            console.log(selected_entity_list);
            let cityName = [];
            let cityId = [];
            selected_entity_list.selected_entity_list.forEach(value=>{
                cityName.push(value.cityName)
                cityId.push(value.id)
            })
            let form = JSON.parse(JSON.stringify(this.form));
            if (this.editType === 'add') {
                this.newSort.cityIds = cityId;
                this.newSort.sortCityName = cityName.join('，');
                form.sortList.push(JSON.parse(JSON.stringify(this.newSort)));
            } else {
                form.sortList[this.activeEditIndex].cityIds = cityId;
                form.sortList[this.activeEditIndex].sortCityName = cityName.join('，');
            }

            this.form = form;
        },
        chooseCityClass(val) {
            this.activeCityClass = val.id;
        },
    }
}
